.technoflex-sidebar {
  width: 280px;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: auto;
  position: sticky;
  top: 0;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
}

.technoflex-sidebar.collapsed {
  width: 88px;
}

.sidebar-header {
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(231, 234, 240, 0.7);
}

.collapse-btn {
  background: rgba(245, 247, 250, 0.8);
  border: 1px solid rgba(231, 234, 240, 0.7);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.collapse-btn:hover {
  background: var(--primary-color);
  color: white;
  transform: translateX(2px);
}

.chevron-icon {
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.chevron-icon.rotate {
  transform: rotate(180deg);
}

.sidebar-nav {
  padding: 24px 16px;
  overflow-y: auto;
  height: calc(100vh - 80px);
}

.sidebar-nav::-webkit-scrollbar {
  width: 6px;
}

.sidebar-nav::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.nav-category {
  margin-bottom: 28px;
}

.category-title {
  color: #8c92a3;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 16px;
  margin-bottom: 16px;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 6px;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  color: #5a607f;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.nav-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 0;
  background: var(--primary-color);
  border-radius: 0 4px 4px 0;
  transition: height 0.3s ease;
}

.nav-item:hover {
  background: rgba(var(--primary-color-rgb), 0.04);
  color: var(--primary-color);
  transform: translateX(4px);
}

.nav-item.active {
  background: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
  font-weight: 500;
}

.nav-item.active::before {
  height: 60%;
}

.nav-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(var(--primary-color-rgb), 0.06);
  transition: all 0.3s ease;
}

.nav-item:hover .nav-item-icon,
.nav-item.active .nav-item-icon {
  background: rgba(var(--primary-color-rgb), 0.12);
}

.nav-icon {
  width: 18px;
  height: 18px;
  transition: transform 0.3s ease;
}

.nav-item:hover .nav-icon {
  transform: scale(1.1);
}

.nav-item-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 14px;
}

.nav-label {
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease;
}

.submenu-container {
  width: 100%;
}

.submenu {
  margin: 4px 0 4px 20px;
  padding-left: 16px;
  border-left: 1px dashed rgba(var(--primary-color-rgb), 0.2);
}

.submenu .nav-item {
  padding: 10px 14px;
}

.submenu .nav-item-icon {
  width: 30px;
  height: 30px;
}

.submenu-arrow {
  width: 16px;
  height: 16px;
  transition: all 0.3s ease;
  opacity: 0.6;
}

.submenu-arrow.rotate {
  transform: rotate(180deg);
  opacity: 1;
  color: var(--primary-color);
}

/* Animation for submenu */
.submenu {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover effect for category titles */
.category-title {
  position: relative;
  display: inline-block;
}

.category-title::after {
  content: '';
  position: absolute;
  left: 16px;
  bottom: -4px;
  width: 20px;
  height: 2px;
  background: var(--primary-color);
  border-radius: 2px;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-category:hover .category-title::after {
  transform: scaleX(1);
}