/* Container layout */
.calendar-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  /* Sidebar styles */
  .calendar-sidebar {
    width: 250px;
    padding: 20px;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .calendar-sidebar h2 {
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .calendar-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .calendar-sidebar ul li {
    padding: 10px;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .calendar-sidebar ul li:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  /* Main calendar section styles */
  .calendar-main {
    flex-grow: 1;
    padding: 40px;
    text-align: center;
  }
  
  .calendar-main h1 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .calendar-main .react-calendar {
    max-width: 600px;
    margin: 0 auto;
    border: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .calendar-main .react-calendar__tile--active {
    background: linear-gradient(135deg, #6a11cb, #2575fc) !important;
    color: white !important;
    border-radius: 5px;
  }
  
  .calendar-main .react-calendar__tile:hover {
    background: rgba(37, 117, 252, 0.2);
    border-radius: 5px;
  }
  
  .calendar-main .selected-date {
    margin-top: 20px;
    font-size: 18px;
    color: #666;
  }
  